<template>
  <v-card class="card text-center">
    <h3 class="card-text mb-8">
      {{ $t("create_campaign_page.suggest_keyword_step_title") }}
    </h3>
    <v-chip
      v-for="item of getSuggestedWordsIsAdded"
      :key="item.text"
      class="h-48 mt-2 ml-2"
      close
      color="#0057FF"
      text-color="white"
      @click:close="item.isAdd = false"
    >
      <strong class="mr-2 font-family-raleway-medium">{{ item.text }}</strong>
    </v-chip>
    <v-divider class="my-4" light></v-divider>
<!--  Get by language  -->
<!--    <div class="d-flex" style="gap: 15px">-->
<!--      <v-select-->
<!--        :items="getCountries"-->
<!--        v-model="location"-->
<!--        style="width: 350px"-->
<!--        item-value="value"-->
<!--        item-text="label"-->
<!--        class="font-family-raleway-medium"-->
<!--        :label="$t('create_campaign_page.location')"-->
<!--        outlined-->
<!--        dense-->
<!--        flat-->
<!--      >-->
<!--        <template v-slot:item="{ item }">-->
<!--          <div>{{ item.label }}</div>-->
<!--        </template>-->
<!--      </v-select>-->
<!--      <v-select-->
<!--        :items="languages"-->
<!--        v-model="language"-->
<!--        style="width: 350px"-->
<!--        item-value="value"-->
<!--        item-text="label"-->
<!--        class="font-family-raleway-medium"-->
<!--        :label="$t('language')"-->
<!--        outlined-->
<!--        dense-->
<!--        flat-->
<!--      >-->
<!--        <template v-slot:item="{ item }">-->
<!--          <div>{{ item.label }}</div>-->
<!--        </template>-->
<!--      </v-select>-->
<!--    </div>-->
    <div v-if="loading">
      <!--      <v-progress-circular indeterminate color="primary"> </v-progress-circular>-->
      <!--      <div class="font-family-raleway-medium">-->
      <!--        {{ $t("create_campaign_page.generating_keywords") }}-->
      <!--      </div>-->
      <covered-loader />
    </div>
    <v-chip
      v-for="item of getSuggestedWordsNotAdded"
      :key="item.text"
      class="mb-2 ml-2 h-48 chip"
      @click="item.isAdd = true"
    >
      <v-icon class="mr-1 icon" color="primary">mdi-plus</v-icon>
      <span class="font-family-raleway-medium">{{ item.text }}</span>
    </v-chip>
    <v-row class="align-center">
      <v-col class="mt-5 font-family-raleway-medium" cols="5">
        <v-btn
          class="back-btn text-decoration-underline"
          @click="handleClickBack"
        >
          {{ $t("back_button") }}
        </v-btn>
      </v-col>
      <v-col class="mt-5 font-family-raleway-medium text-right" cols="7">
        <v-btn
          class="button font-weight-bold next-btn font-family-raleway-medium"
          height="60"
          @click="handleClick"
        >
          {{ $t("create_campaign_page.next_button") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { campaignService } from "../../services/campaign";
import countriesData from "@/assets/json-data/countries.json";

export default {
  name: "SuggestKeyword",
  components: {
    CoveredLoader: () => import("@/components/CoveredLoader")
  },
  data: () => ({
    suggestedWords: [],
    loading: false,
    countries: countriesData,
    allKeywords: [],
    location: "Uzbekistan",
    language: "en",
    cities: [],
    languages: [
      {
        label: "English",
        value: "en",
      },
      {
        label: "Russian",
        value: "ru",
      },
    ],
  }),
  computed: {
    getSuggestedWordsNotAdded() {
      return this.suggestedWords.filter((item) => !item.isAdd);
    },
    getSuggestedWordsIsAdded() {
      return this.suggestedWords.filter((item) => item.isAdd);
    },
    getCountries() {
      return this.countries.map((item) => {
        return {
          value: item.country,
          label: item.country,
        };
      });
    },
  },
  async created() {
    this.language = this.$i18n.locale;
    const campaign = localStorage.getItem("campaign");
    const allKeywords = localStorage.getItem("allKeywords");
    const parseKeywords = JSON.parse(allKeywords);
    const parseToObj = JSON.parse(campaign);
    this.campaignItem = parseToObj;
    if (parseToObj?.keywords) {
      this.suggestedWords = [
        ...this.suggestedWords,
        ...parseToObj?.keywords.map((item) => {
          return { text: item, isAdd: true };
        }),
      ];
    }
    if (parseKeywords) {
      const array1 = parseKeywords;
      const array2 = this.suggestedWords.map((item) => item.text);
      const result = array1.filter((item) => !array2.includes(item));
      this.suggestedWords = [
        ...this.suggestedWords,
        ...result.map((item) => {
          return { text: item, isAdd: false };
        }),
      ];
    } else {
      await this.getKeywordsData();
    }
  },
  methods: {
    async getKeywordsData() {
      const customerId = localStorage.getItem("customerId");
      const campaign = localStorage.getItem("campaign");
      const parseToObj = JSON.parse(campaign);
      this.loading = true;
      try {
        const responseKeywords = await campaignService.getSuggestedKeywords(
          parseToObj.url,
          customerId,
          parseToObj?.keywords,
          this.language,
          this.location
        );
        const array1 = responseKeywords.data;
        const array2 = this.suggestedWords.map((item) => item.text);
        const result = array1.filter((item) => !array2.includes(item));
        this.allKeywords = responseKeywords.data;
        this.suggestedWords = [
          ...this.suggestedWords,
          ...result?.map((item) => {
            return { text: item, isAdd: false };
          }),
        ];
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    handleClick() {
      const keys = this.suggestedWords.filter((item) => item.isAdd === true);
      this.campaignItem.keywords = keys.map((item) => item.text);
      localStorage.setItem("campaign", JSON.stringify(this.campaignItem));
      localStorage.setItem("allKeywords", JSON.stringify(this.allKeywords));
      this.$router.push("show-address");
    },
    handleClickBack() {
      this.$router.push("basic-info");
      localStorage.removeItem("allKeywords");
    },
  },
  watch: {
    language() {
      this.getKeywordsData();
    },
    location() {
      this.getKeywordsData();
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "../../../assets/style/style.scss";

.chip {
  font-size: 16px;
  cursor: pointer;
  background: rgba(231, 231, 231, 0.98) !important;
  .icon {
    font-size: 25px;
  }
}
</style>
